import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './routes/Routes';
import withRandomBackground from './components/RandomBackground';
import 'bulma/css/bulma.min.css';

const AppContent = withRandomBackground(Routers);

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;