import React, { useEffect, useState, Suspense } from 'react';
import Footer from './Footer';
import SegmindPixelFlow from './SegmindPixelFlow';

const NavPublic = React.lazy(() => import('./NavPublic'));

function Segmind({ colorView }) {
  
  return (
    <>
    <section className="theme-light section m-0 p-0" data-theme="light">
      <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-space-between">
        <div className="is-flex is-flex-direction-column">
          {/* Navigation */}
          <div className="p-6 m-0">
            <Suspense fallback={<div>Loading...</div>}>
              <NavPublic colorView="light" />
            </Suspense>
          </div>

          <div className='is-flex is-flex-direction-column pt-6 m-0'>
            <p className='pb-1 container is-size-3 has-text-weight-bold has-text-white has-text-left'>
              AI spotlight
            </p>
            <div className='container is-flex is-justify-content-space-between'>
              <p className='is-size-4 has-text-weight-bold has-text-white has-text-left'>
                Meme generator using PixelFlow by Segmind
              </p>
              <p className='is-size-4 has-text-weight-bold has-text-white has-text-right'>
                <a href="https://segmind.com?utm_source=guavaicecream" target='_blank'>
                  Visit Segmind.com
                </a>
              </p>
            </div>
            <p className='py-2 container is-size-6 has-text-weight-normal has-text-white has-text-left'>
            This experiment uses one LLM, ChatGPT 4o, and three text-to-image models: Ideogram, Stable Diffusion 3.5 Turbo, and Recraft V3.
            </p>
            <div className='container pt-5 pb-6'>
              <SegmindPixelFlow />
            </div>
          </div>
        </div>      
      </div>
    </section>
    
    <section className='pb-6'>
      {/* <Footer /> */}
    </section>
    </>
  );
}

export default Segmind;